import style from '../pages/style/faq.module.scss'

import Blue from '../images/faq/plants/blue-leaf.svg'
import Green from '../images/faq/plants/green-leaf.svg'
import White from '../images/faq/plants/white-leaf.svg'
import Yellow from '../images/faq/plants/yellow-leaf.svg'

export const plants = [{
  id: style.Blue,
  aos: 'faq-blue',
  alt: 'Blue algae',
  src: Blue,
},{
  id: style.Yellow,
  aos: 'faq-yellow',
  alt: 'yellow algae',
  src: Yellow,
},{
  id: style.Green,
  aos: 'faq-green',
  alt: 'Green algae',
  src: Green,
},{
  id: style.White,
  aos: 'faq-white',
  alt: 'White algae',
  src: White,
},];